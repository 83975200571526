import { useState } from "react";
import { getFirestore, doc, setDoc } from "firebase/firestore"; 
import { initializeApp } from "firebase/app";
import CustomTextField from "./CustomTextField";
import Button from "./Button";

const firebaseConfig = {
  apiKey: "AIzaSyBbOvQXijrgYbkVD08AT_Yifz-PPKSqGO4",
  authDomain: "foracto-30778.firebaseapp.com",
  projectId: "foracto-30778",
  storageBucket: "foracto-30778.appspot.com",
  messagingSenderId: "553211892927",
  appId: "1:553211892927:web:7cff3662388133bcc4a20a"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export default function Contact() {
    const [form, setForm] = useState({
      fullName: '',
      subject: '',
      email: '',
      message: '',
    });
    const [status, setStatus] = useState("");
    const [errors, setErrors] = useState({});

    const handleChange = (e) => {
      setForm({
        ...form,
        [e.target.name]: e.target.value,
      });
    };

    const validateForm = () => {
      let formErrors = {};

      if (!form.fullName) formErrors.fullName = "*Nom complet est requis.";
      if (!form.subject) formErrors.subject = "*Sujet est requis.";
      if (!form.email) formErrors.email = "*Email est requis.";
      if (!form.message) formErrors.message = "*Message est requis.";

      return formErrors;
    };

    const handleSubmit = async (e) => {
      e.preventDefault();
      const newErrors = validateForm();
      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
        return;
      }
      
      try {
        await setDoc(doc(db, "contacts", form.subject + " " + form.email), form);
        setStatus("Le message a bien été envoyé");
    
        setTimeout(() => {
          setForm({
            fullName: '',
            subject: '',
            email: '',
            message: '',
          });
          setStatus("");
        }, 3000);
      } catch (error) {
        setStatus("Error");
      }
    };
    

    return (
      <form onSubmit={handleSubmit}>
        <div className="DivForm">
          <CustomTextField
            type="text" // Le type doit être "text" et non "fullName"
            name="fullName"
            id="fullName"
            value={form.fullName}
            onChange={handleChange}
            autoComplete="name"
            label="Nom complet"
            variant="outlined"
          />
          {errors.fullName && <p>{errors.fullName}</p>}
        </div>
        <div className="DivForm2">
          <div className="DivForm3">
            <CustomTextField
              type="text" // Le type doit être "text" et non "subject"
              name="subject"
              id="subject"
              value={form.subject}
              onChange={handleChange}
              autoComplete="off" // Désactivez l'autocomplétion si nécessaire
              label="Sujet"
              variant="outlined"
            />
            {errors.subject && <p>{errors.subject}</p>}
          </div>
    
          <div className="DivForm3">
            <CustomTextField
              type="email"
              name="email"
              id="email"
              value={form.email}
              onChange={handleChange}
              autoComplete="email"
              label="Adresse e-mail"
              variant="outlined"
            />
            {errors.email && <p>{errors.email}</p>}
          </div>
        </div>
        <div className="DivForm">
          <CustomTextField
            type="text" // Le type doit être "text" et non "message"
            name="message"
            id="message"
            value={form.message}
            onChange={handleChange}
            autoComplete="off" // Désactivez l'autocomplétion si nécessaire
            label="Comment pouvons-nous vous aider?"
            variant="outlined"
            multiline
            rows={4}
          />
          {errors.message && <p>{errors.message}</p>}
        </div>
        <div className="DivForm4">
          <Button type="submit">Envoyer</Button>
          <p>{status}</p>
        </div>
      </form>
    );
    
}
