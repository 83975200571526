import React from "react";
import "./TeamCard.css";

const TeamCard = ({ name, role, imageUrl, linkedInUrl }) => {
  return (
    <div>
      <div className="team-card">
        <a href={linkedInUrl} target="_blank" rel="noreferrer">
          <img src={imageUrl} alt={name} className="team-card-img"/>
        </a>
      </div>
      <div className="TeamCardText">
        <h3 className="name">{name}</h3>
        <p className="role">{role}</p>
      </div>
    </div>
  );
};

export default TeamCard;
