import React from 'react';
import './MethodCard.css';

const MethodCard = ({ number, title, text }) => {
  return (
    <div className="method-card">
      <div className="method-number">{number}</div>
      <h2 className="method-title">{title}</h2>
      <p className="method-text">{text}</p>
    </div>
  );
};

export default MethodCard;
