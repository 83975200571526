import React, { useState, useEffect } from 'react';
import { initializeApp } from 'firebase/app';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs, doc, updateDoc } from 'firebase/firestore';
import Button from "../components/Button";
import Footer from "../components/Footer";
import './accueil'; // Assurez-vous d'avoir ce fichier CSS

// Configuration Firebase
const firebaseConfig = {
  apiKey: "AIzaSyBbOvQXijrgYbkVD08AT_Yifz-PPKSqGO4",
  authDomain: "foracto-30778.firebaseapp.com",
  projectId: "foracto-30778",
  storageBucket: "foracto-30778.appspot.com",
  messagingSenderId: "553211892927",
  appId: "1:553211892927:web:7cff3662388133bcc4a20a"
};

// Initialisation de Firebase
const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const MessagesPage = () => {
  const [untreatedMessages, setUntreatedMessages] = useState([]);
  const [treatedMessages, setTreatedMessages] = useState([]);
  const [password, setPassword] = useState('');
  const [isAuthenticated, setIsAuthenticated] = useState(false);

  const navigate = useNavigate();
  const goToHomePage = () => {
    navigate('/'); // Redirige vers la page d'accueil
  };

  // Définition de fetchMessages
  const fetchMessages = async () => {
    try {
      const querySnapshot = await getDocs(collection(db, 'contacts'));
      const messages = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));

      // Tri des messages en deux catégories
      const untreated = messages.filter(message => !message.treated);
      const treated = messages.filter(message => message.treated);

      setUntreatedMessages(untreated);
      setTreatedMessages(treated);
    } catch (error) {
      console.error("Erreur lors du chargement des messages:", error);
    }
  };

  useEffect(() => {
    fetchMessages();
  }, []);

  const handleCheckboxChange = async (id, treated) => {
    // Mise à jour de Firestore
    const messageRef = doc(db, 'contacts', id);
    await updateDoc(messageRef, {
      treated: !treated
    });

    // Recharger les messages après la mise à jour
    fetchMessages();
  };

  const renderMessages = (messages) => (
    <ul>
      {messages.map(message => (
        <div className='UnTreateDiv' key={message.id}>
          <li className={message.treated ? 'message-treated' : 'message-untreated'}>
            <p><strong>Email:</strong> {message.email}</p>
            <p><strong>Nom complet:</strong> {message.fullName}</p>
            <p><strong>Sujet:</strong> {message.subject}</p>
            <p><strong>Message:</strong> {message.message}</p>
            <input 
              type="checkbox" 
              checked={message.treated || false}
              onChange={() => handleCheckboxChange(message.id, message.treated)} 
            />
          </li>
        </div>
      ))}
    </ul>
  );

  const verifyPassword = () => {
    const correctPassword = "ForactoMessagePageMDP."; // Remplacez par votre mot de passe
    if (password === correctPassword) {
      setIsAuthenticated(true);
    } else {
      alert("Mot de passe incorrect !");
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="password-protect">
        <input 
          type="password" 
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Entrez le mot de passe"
        />
        <button onClick={verifyPassword}>Valider</button>
      </div>
    );
  }

  return (
    <div className='GeneralMessages'>
      <div className='Retour'>
      <Button onClick={goToHomePage}>Accueil</Button>
      </div>
      <div className='Messnontraite'>
        <h2 className="TitreDiv">Messages Non Traités</h2>
        {renderMessages(untreatedMessages)}
      </div>
      <div className='Messtraite'>
        <h2 className="TitreDiv">Messages Traités</h2>
        {renderMessages(treatedMessages)}
      </div>
        <Footer />
    </div>
  );
};

export default MessagesPage;
