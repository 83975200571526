import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../assets/logo.svg'; 
import Button from './Button';
import './Header.css';

const Header = ({scrollToContact, scrollToOffre}) => {
    return (
        <header className="header">
            <img src={Logo} alt="Logo" />
            <nav>
                <Link onClick={scrollToOffre}>Nos services</Link>
                <Button onClick={scrollToContact}>
                    Discutons-en
                </Button>
            </nav>
        </header>
    );
}


export default Header;
