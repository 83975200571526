import React from "react";
import "./accueil.css";
import TeamCard from "../components/TeamCard";
import MethodCard from "../components/MethodCard";
import OffreCard from "../components/OffreCard";
import Button from "../components/Button";
import Header from "../components/Header";
import MichaelImage from "../assets/MichaelCohen.png";
import ContactForm from "../components/Contact";
import Footer from "../components/Footer";

const Accueil = () => {
  const ContactRef = React.useRef(null);
  const OffreRef = React.useRef(null);

  const scrollToContact = () => {
    window.scrollTo({
      top: ContactRef.current.offsetTop - 200,
      behavior: "smooth",
    });
  };

  const scrollToOffre = () => {
    window.scrollTo({
      top: OffreRef.current.offsetTop - 200,
      behavior: "smooth",
    });
  };

  const teamData = [
    {
      name: "Michael Cohen",
      role: "CTO",
      imageUrl: MichaelImage,
      linkedInUrl: "https://www.linkedin.com/in/michaelcohencto/", // Remplacez par l'URL correcte
    },
  ];

  const offreData = [
    {
      title: "Audit de Sécurité",
      text: "Analyse complète de vos systèmes. Recherche de vulnérabilités. Mise en place de mesures de sécurité".split(
        "."
      ),
    },
    {
      title: "Consulting",
      text: "Conseil en management de transition. Évaluations de faisabilité technologique. Identification des opportunités. Développement de stratégies de digitalisation".split(
        "."
      ),
    },
    {
      title: "Management de Transition",
      text: "Gestion de vos opérations. Développement de nouvelles activités. Amélioration de l'efficacité".split(
        "."
      ),
    },
  ];

  return (
    <div className="Body">
      <div className="Head">
        <Header
          scrollToContact={scrollToContact}
          scrollToOffre={scrollToOffre}
        ></Header>
        <div className="content-head">
          <h2 className="TitreHead">
            Nous concevons des stratégies opérationnelles sur demande, conçues pour faire évoluer votre entreprise.
          </h2>
          <ul>
            <li>
              Gagnez du <span className="highlight">temps</span> et augmentez
              votre <span className="highlight">efficacité</span> grâce à nos audits de sécurité et de performance.
            </li>
            <li>
              <span className="highlight">Des analyses précises</span> et efficaces pour prendre les <span className="highlight">meilleures décisions</span>.
            </li>
            <li>
              <span className="highlight">Des solutions de conseil adaptées</span> pour accompagner et faire évoluer votre entreprise.
            </li>
          </ul>
          <Button onClick={scrollToContact}>Discutons-en</Button>
        </div>
      </div>

      <div className="Offre" ref={OffreRef}>
        <h2 className="TitreDiv">Ce que l’on peut faire pour vous</h2>
        <div className="offre-grid">
          {offreData.map((offre, index) => (
            <OffreCard key={index} title={offre.title} text={offre.text} />
          ))}
        </div>
      </div>
      <div className="Methodologie">
        <h2 className="TitreDiv">Notre méthodologie</h2>
        <div className="metho12">
          <MethodCard
            number="1"
            title="Compréhension de vos besoins, objectifs"
            text="Nous commençons par une réunion de découverte où nous cernons vos problèmes et objectifs spécifiques. Cela nous permet d'identifier les domaines où nos services peuvent vous aider à optimiser votre efficacité."
          />
          <MethodCard
            number="2"
            title="Présentation des stratégies possibles"
            text="Une fois que nous avons une compréhension claire de vos besoins, nous vous présentons une variété de stratégies de transition et de sécurité adaptées. Ensemble, nous définissons un plan d'action pour la mise en œuvre de ces solutions."
          />
        </div>
        <div className="metho34">
          <MethodCard
            number="3"
            title="Mise en œuvre et amélioration de la solution"
            text="Après la mise en place de la solution, nous assurons un suivi pour vous aider à comprendre et à utiliser efficacement les nouvelles stratégies. Nous restons également disponibles pour apporter toute amélioration nécessaire et pour assurer un support technique continu, afin que vous puissiez tirer le meilleur parti de nos services."
          />
          <MethodCard
            number="4"
            title="Suivi et développement de la solution"
            text="Nous mettons en œuvre les stratégies choisies, que ce soit dans le domaine de la transition managériale, des audits de sécurité, ou autres. En cours de développement, nous assurons un suivi régulier pour garantir que la solution répond à vos attentes et pour apporter les ajustements nécessaires."
          />
        </div>
      </div>

      <div className="Equipe">
        <h2 className="TitreDiv">Découvrez Notre Équipe</h2>
        <div className="DivEquipe">
          {teamData.map((member) => (
            <TeamCard
              key={member.name}
              name={member.name}
              role={member.role}
              imageUrl={member.imageUrl}
              linkedInUrl={member.linkedInUrl}
            />
          ))}
        </div>
      </div>
      <div className="Apropos">
        <h2 className="TitreDiv">À Propos</h2>
        <p className="TextPropos">

        Foracto, fondée par Michael Cohen, se spécialise en transition managériale et sécurité numérique, axée sur l'accompagnement des entreprises dans leur digitalisation. Michael, en tant que fondateur et CTO, sélectionne des équipes sur mesure pour chaque projet, en s'appuyant sur sa passion pour la technologie et son expérience en gestion technologique.</p>
<p className="TextPropos">
Ses compétences couvrent une gamme de technologies et de systèmes de sécurité, avec une expertise particulière dans le voyage et les industries PLM. Plus qu'un technicien, Michael est un leader orienté client, engagé à délivrer des solutions qui dépassent les attentes.
</p>    
      </div>
      <div className="Contact" ref={ContactRef}>
        <h2 className="TitreDiv">Contact</h2>
        <ContactForm />
      </div>
      <div className="footer">
        <Footer />
      </div>
    </div>
  );
};

export default Accueil;
