import React from 'react';
import './OffreCard.css';

const OffreCard = ({ title, text }) => {
  const titleParts = title.split('.');

  return (
    <div className="offre-card">
      {titleParts.map((part, index) => (
        <h2 key={index} className="offre-title">{part}</h2>
      ))}
      {text.map((line, index) => (
        <p key={index} className="offre-text">{line}</p>
      ))}
    </div>
  );
};

export default OffreCard;
