import { HashRouter as Router, Route, Routes } from 'react-router-dom';
import Accueil from '../pages/accueil';
import './App.css';
import MessagesPage from '../pages/MessagesPage';

function App() {
  return (
    <Router basename='/'>
      <Routes>
        <Route path="/" element={<Accueil/>} />
        <Route path="/messagespage" element={<MessagesPage/>} />
      </Routes>
    </Router>
  );
}

export default App;
